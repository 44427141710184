/**Author: TemplateMag.com/License: https://templatemag.com/license/*/
@font-face {
    font-family: 'Ruda';
    src: url('../fonts/Ruda-Regular.eot');
    /* IE9 Compat Modes */
    src: url('../fonts/Ruda-Regular.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        /*url('../fonts/Ruda-Regular.woff2') format('woff2'), /* Super Modern Browsers */
        url('../fonts/Ruda-Regular.woff') format('woff'),
        /* Pretty Modern Browsers */
        url('../fonts/Ruda-Regular.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('../fonts/Ruda-Regular.svg#svgFontName') format('svg');
    /* Legacy iOS */
}

body {
    color: #000000;
    background: #ffffff;
    font-family: 'Ruda', sans-serif;
    padding: 0px !important;
    margin: 0px !important;
    font-size: 14px;
}

ul li {
    list-style: none;
}

a,
a:hover,
a:focus {
    text-decoration: none;
    outline: none;
}

::selection {

    background: #6db3ef;
    color: #fff;
}

::-moz-selection {
    background: #6db3ef;
    color: #fff;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

#container {
    width: 100%;
    height: 100%;
}

.modal-header {
    background: #3da0de;
}

.modal-content {
    border-radius: 20px !important;
}

.modal-header {
    border-radius: 20px 20px 0 0;
}

.modal-title {
    color: white;
}

.btn-round {
    border-radius: 20px;
    -webkit-border-radius: 20px;
}

.spin {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    color: grey;
}

.accordion-heading .accordion-toggle {
    display: block;
    cursor: pointer;
    border-top: 1px solid #F5F5F5;
    padding: 5px 0px;
    line-height: 28.75px;
    text-transform: uppercase;
    color: #1a1a1a;
    background-color: #ffffff;
    outline: none !important;
    text-decoration: none;
}

/*Theme Backgrounds*/
.bg-theme {
    background-color: #4ECDC4;
}

.bg-theme02 {
    background-color: #ac92ec;
}

.bg-theme03 {
    background-color: #48cfad;
}

.bg-theme04 {
    background-color: #ed5565;
}

/*Theme Buttons*/

.btn-theme {
    color: #fff;
    background-color: #3da0de;
    border-color: #2a90d2;
}

.btn-theme:hover,
.btn-theme:focus,
.btn-theme:active,
.btn-theme.active,
.open .dropdown-toggle.btn-theme {
    color: #fff;
    background-color: #3388c8;
    border-color: #0f6094;
}

.btn-theme02 {
    color: #fff;
    background-color: #ac92ec;
    border-color: #967adc;
}

.btn-theme02:hover,
.btn-theme02:focus,
.btn-theme02:active,
.btn-theme02.active,
.open .dropdown-toggle.btn-theme02 {
    color: #fff;
    background-color: #967adc;
    border-color: #967adc;
}

.btn-theme03 {
    color: #fff;
    background-color: #48cfad;
    border-color: #37bc9b;
}

.btn-theme03:hover,
.btn-theme03:focus,
.btn-theme03:active,
.btn-theme03.active,
.open .dropdown-toggle.btn-theme03 {
    color: #fff;
    background-color: #37bc9b;
    border-color: #37bc9b;
}

.btn-theme04 {
    color: #fff;
    background-color: #ed5565;
    border-color: #da4453;
}

.btn-theme04:hover,
.btn-theme04:focus,
.btn-theme04:active,
.btn-theme04.active,
.open .dropdown-toggle.btn-theme04 {
    color: #fff;
    background-color: #da4453;
    border-color: #da4453;
}

.btn-clear-g {
    color: #48bcb4;
    background: transparent;
    border-color: #48bcb4;
}

.btn-clear-g:hover {
    color: white;
}

hr {
    margin-top: 20px;
    margin-bottom: 20px;
    border: 0;
    border-top: 1px solid #9e9fa0;
    width: 100%;
}



/*Helpers*/

.centered {
    text-align: center;
}

.goleft {
    text-align: left;
}

.goright {
    text-align: right;
}

.mt {
    margin-top: 25px;
}

.mb {
    margin-bottom: 25px;
}


.no-padding {
    padding: 0 !important;
}

.no-margin {
    margin: 0 !important;
}

/*Exclusive Theme Colors Configuration*/

.label-theme {
    background-color: #4ECDC4;
}

.bg-theme {
    background-color: #4ECDC4;
}

ul.top-menu>li>.logout {
    color: #f2f2f2;
    font-size: 12px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    border: 1px solid #64c3c2 !important;
    padding: 5px 15px;
    margin-right: 15px;
    background: #4ECDC4;
    margin-top: 15px;
}


/*sidebar navigation*/

#sidebar {
    width: 210px;
    height: 100%;
    position: fixed;
    background: #f8f8f8;
    border-right: 1px solid #9e9fa0;
}

#sidebar h5 {
    color: #f2f2f2;
    font-weight: 700;
}

#sidebar ul li {
    position: relative;
}

#sidebar .sub-menu>.sub li {
    padding-left: 32px;
}

.sub li {
    padding-left: 32px;
}

#sidebar .sub-menu>.sub li:last-child {
    padding-bottom: 10px;
}

.sub li:last-child {
    padding-bottom: 10px;
}

/*LEFT NAVIGATION ICON*/
.dcjq-icon {
    height: 17px;
    width: 17px;
    display: inline-block;
    /* background: url("../img/nav-expand.png") no-repeat top; */
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    position: absolute;
    right: 10px;
    top: 15px;
}

.active .dcjq-icon {
    /* background: url("../img/nav-expand.png") no-repeat bottom; */
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
}

/*---*/

.nav-collapse.collapse {
    display: inline;
}

ul.sidebar-menu,
ul.sidebar-menu li ul.sub {
    margin: -2px 0 0;
    padding: 0;
}

ul.sidebar-menu {
    margin-top: 75px;
}

#sidebar>ul>li>ul.sub {
    display: none;
}

#sidebar>ul>li.active>ul.sub,
#sidebar>ul>li>ul.sub>li>a {
    display: block;
}

ul.sidebar-menu li ul.sub li {
    background: #2f323a;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
}

ul.sidebar-menu li ul.sub li:last-child {
    border-radius: 0 0 4px 4px;
    -webkit-border-radius: 0 0 4px 4px;
}

ul.sidebar-menu li ul.sub li a {
    font-size: 14px;
    padding: 6px 0;
    line-height: 35px;
    height: 35px;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
    color: #aeb2b7;
}

ul.sidebar-menu li ul.sub li a:hover {
    color: white;
    background: transparent;
}

ul.sidebar-menu li ul.sub li.active a {
    color: #4ECDC4;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
    display: block;
}

ul.sidebar-menu li {
    /*line-height: 20px !important;*/
    margin-bottom: 5px;
    margin-left: 10px;
    margin-right: 10px;
}

ul.sidebar-menu li.sub-menu {
    line-height: 15px;
}

ul.sidebar-menu li a span {
    display: inline-block;
}

ul.sidebar-menu li a {
    color: #000000;
    text-decoration: none;
    display: block;
    padding: 15px 0 15px 10px;
    font-size: 14px;
    outline: none;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

ul.sidebar-menu li a.active,
ul.sidebar-menu li a:hover,
ul.sidebar-menu li a:focus {
    background: #3E4095;
    color: #fff;
    display: block;

    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
}


ul.sidebar-menu li a i {
    font-size: 15px;
    padding-right: 6px;
}

ul.sidebar-menu li a:hover i,
ul.sidebar-menu li a:focus i {
    color: #fff;
}

ul.sidebar-menu li a.active i {
    color: #fff;
}


.mail-info,
.mail-info:hover {
    margin: -3px 6px 0 0;
    font-size: 11px;
}

/* MAIN CONTENT CONFIGURATION */
#main-content {
    margin-left: 210px;

}

.header,
.footer {
    min-height: 60px;
    /* padding: 0 15px; */
}

.header {
    position: fixed;
    left: 0;
    right: 0;
    z-index: 1002;
}

.black-bg {
    background: #f8f8f8;
    border-bottom: 1px solid #9e9fa0;
}

.wrapper {
    display: inline-block;
    margin-top: 60px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    padding-top: 0px;
    width: 100%;
    word-wrap: break-word;
}

a.logo {
    font-size: 24px;
    color: #f2f2f2;
    float: left;
    margin-top: 5px;
    text-transform: uppercase;
}

.hammenu {
    color: #000000;
    float: left;
    margin-top: 30px;
    margin-right: 20px;
}

/*notification*/
#top_menu .nav>li,
ul.top-menu>li {
    float: left;
}

.notify-row {
    float: left;
    margin-top: 15px;
    margin-left: 92px;
}

.notify-row .notification span.label {
    display: inline-block;
    height: 18px;
    width: 20px;
    padding: 5px;
}

ul.top-menu>li>a {
    color: #666666;
    font-size: 16px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    border: 1px solid #666666 !important;
    padding: 2px 6px;
    margin-right: 15px;
}

ul.top-menu>li>a:hover,
ul.top-menu>li>a:focus {
    border: 1px solid #b6b6b6 !important;
    background-color: transparent !important;
    border-color: #b6b6b6 !important;
    text-decoration: none;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    color: #b6b6b6 !important;
}


.dropdown-menu.extended {
    max-width: 300px !important;
    min-width: 160px !important;
    top: 42px;
    width: 235px !important;
    padding: 0;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.176) !important;
    border: none !important;
    border-radius: 4px;
    -webkit-border-radius: 4px;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {

    /* Safari and Chrome */
    .dropdown-menu.extended {
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.176) !important;
    }

    ;
}

.dropdown-menu.extended li p {
    background-color: #F1F2F7;
    color: #666666;
    margin: 0;
    padding: 10px;
    border-radius: 4px 4px 0px 0px;
    -webkit-border-radius: 4px 4px 0px 0px;
}

.dropdown-menu.extended li p.green {
    background-color: #4ECDC4;
    color: #fff;
}

.dropdown-menu.extended li p.yellow {
    background-color: #fcb322;
    color: #fff;
}

.dropdown-menu.extended li a {
    border-bottom: 1px solid #EBEBEB !important;
    font-size: 12px;
    list-style: none;
}

.dropdown-menu.extended li a {
    padding: 15px 10px !important;
    width: 100%;
    display: inline-block;
}

.dropdown-menu.extended li a:hover {
    background-color: #F7F8F9 !important;
    color: #2E2E2E;
}

.dropdown-menu.tasks-bar .task-info .desc {
    font-size: 13px;
    font-weight: normal;
}

.dropdown-menu.tasks-bar .task-info .percent {
    display: block;
    float: right;
    font-size: 13px;
    font-weight: 600;
    padding-left: 10px;
    margin-top: -4px;
}

.dropdown-menu.extended .progress {
    margin-bottom: 0 !important;
    height: 10px;
}

.dropdown-menu.inbox li a .photo img {
    border-radius: 2px 2px 2px 2px;
    float: left;
    height: 40px;
    margin-right: 4px;
    width: 40px;
}

.dropdown-menu.inbox li a .subject {
    display: block;
}

.dropdown-menu.inbox li a .subject .from {
    font-size: 12px;
    font-weight: 600;
}

.dropdown-menu.inbox li a .subject .time {
    font-size: 11px;
    font-style: italic;
    font-weight: bold;
    position: absolute;
    right: 5px;
}

.dropdown-menu.inbox li a .message {
    display: block !important;
    font-size: 11px;
}

.top-nav {
    margin-top: 7px;
}

.top-nav ul.top-menu>li .dropdown-menu.logout {
    width: 268px !important;

}

.top-nav li.dropdown .dropdown-menu {
    float: right;
    right: 0;
    left: auto;
}

.dropdown-menu.extended.logout>li {
    float: left;
    text-align: center;
    width: 33.3%;
}

.dropdown-menu.extended.logout>li:last-child {
    float: left;
    text-align: center;
    width: 100%;
    background: #a9d96c;
    border-radius: 0 0 3px 3px;
}

.dropdown-menu.extended.logout>li:last-child>a,
.dropdown-menu.extended.logout>li:last-child>a:hover {
    color: #fff;
    border-bottom: none !important;
    text-transform: uppercase;
}

.dropdown-menu.extended.logout>li:last-child>a:hover>i {
    color: #fff;
}

.dropdown-menu.extended.logout>li>a {
    color: #a4abbb;
    border-bottom: none !important;
}

.full-width .dropdown-menu.extended.logout>li>a:hover {
    background: none !important;
    color: #50c8ea !important;
}

.dropdown-menu.extended.logout>li>a:hover {
    background: none !important;
}

.dropdown-menu.extended.logout>li>a:hover i {
    color: #50c8ea;
}

.dropdown-menu.extended.logout>li>a i {
    font-size: 17px;
}

.dropdown-menu.extended.logout>li>a>i {
    display: block;
}

.top-nav ul.top-menu>li>a {
    border: 1px solid #eeeeee;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    padding: 6px;
    background: none;
    margin-right: 0;
}

.top-nav ul.top-menu>li {
    margin-left: 10px;
}

.top-nav ul.top-menu>li>a:hover,
.top-nav ul.top-menu>li>a:focus {
    border: 1px solid #F1F2F7;
    background: #F1F2F7;

}

.top-nav .dropdown-menu.extended.logout {
    top: 50px;
}

.top-nav .nav .caret {
    border-bottom-color: #A4AABA;
    border-top-color: #A4AABA;
}

.top-nav ul.top-menu>li>a:hover .caret {
    border-bottom-color: #000;
    border-top-color: #000;
}

.log-arrow-up {
    /* background: url("../img/arrow-up.png") no-repeat; */
    width: 20px;
    height: 11px;
    position: absolute;
    right: 20px;
    top: -10px;
}

/*----*/

.notify-arrow {
    border-style: solid;
    border-width: 0 9px 9px;
    height: 0;
    margin-top: 0;
    opacity: 0;
    position: absolute;
    left: 7px;
    top: -18px;
    transition: all 0.25s ease 0s;
    width: 0;
    z-index: 10;
    margin-top: 10px;
    opacity: 1;
}

.notify-arrow-yellow {
    border-color: transparent transparent #FCB322;
    border-bottom-color: #FCB322 !important;
    border-top-color: #FCB322 !important;
}

.notify-arrow-green {
    border-color: transparent transparent #4ECDC4;
    border-bottom-color: #4ECDC4 !important;
    border-top-color: #4ECDC4 !important;
}



/*--sidebar toggle---*/

.sidebar-toggle-box {
    float: left;
    padding-right: 15px;
    margin-top: 20px;
}

.sidebar-toggle-box .fa-bars {
    cursor: pointer;
    display: inline-block;
    font-size: 20px;
}


.sidebar-closed>#sidebar>ul {
    display: none;
}

.sidebar-closed #main-content {
    margin-left: 0px;
}

.sidebar-closed #sidebar {
    margin-left: -180px;
}


/* Dash Side */

.ds {
    background: #d6d9dc;
    padding-top: 20px;
}

.ds h4 {
    font-size: 14px;
    font-weight: 700;
}

.ds i {
    font-size: 14px;
}

.ds .desc {
    border-bottom: 1px dotted #979797;
    display: inline-block;
    padding: 15px 0;
    width: 100%;
}

.ds .desc:hover {
    background: #ffffff;
}

.ds .thumb {
    width: 30px;
    margin: 0 10px 0 20px;
    display: block;
    float: left;
}

.ds .details {
    width: 170px;
    float: left;
}

.ds>.desc p {
    font-size: 11px;
}

.ds p>muted {
    font-size: 9px;
    text-transform: uppercase;
    font-style: italic;
    color: #666666
}

.ds a {
    color: #4ECDC4;
}

/* MAIN CHART CONFIGURATION */
.main-chart {
    padding-top: 20px;
}

.border-head h3 {
    border-bottom: 1px solid #c9cdd7;
    margin-top: 0;
    margin-bottom: 20px;
    padding-bottom: 5px;
    font-weight: normal;
    font-size: 18px;
    display: inline-block;
    width: 100%;
    font-weight: 300;
}

.custom-bar-chart {
    height: 290px;
    margin-top: 20px;
    margin-left: 10px;
    position: relative;
    border-bottom: 1px solid #c9cdd7;
}

.custom-bar-chart .bar {
    height: 100%;
    position: relative;
    width: 6%;
    margin: 0px 4%;
    float: left;
    text-align: center;
    z-index: 10;
}

.custom-bar-chart .bar .title {
    position: absolute;
    bottom: -30px;
    width: 100%;
    text-align: center;
    font-size: 11px;
}

.custom-bar-chart .bar .value {
    position: absolute;
    bottom: 0;
    background: #4ECDC4;
    color: #4ECDC4;
    width: 100%;
    -webkit-border-radius: 5px 5px 0 0;
    -moz-border-radius: 5px 5px 0 0;
    border-radius: 5px 5px 0 0;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
}



.custom-bar-chart .bar .value:hover {
    background: #2f2f2f;
    color: #fff;
}

.y-axis {
    color: #555555;
    position: absolute;
    text-align: left;
    width: 100%;
    font-size: 11px;
}

.y-axis li {
    border-top: 1px dashed #dbdce0;
    display: block;
    height: 58px;
    width: 100%;
}

.y-axis li:last-child {
    border-top: none;
}

.y-axis li span {
    display: block;
    margin: -10px 0 0 -60px;
    padding: 0 10px;
    width: 40px;
}


/*Donut Chart Main Page Conf*/
.donut-main {
    display: block;
    text-align: center;
}

.donut-main h4 {
    font-weight: 700;
    font-size: 16px;
}

/* *************************************************************************************
  PANELS CONFIGURATIONS
  *************************************************************************************** */

/*Panel Size*/

.pn {
    height: 250px;
    box-shadow: 0 2px 1px rgba(0, 0, 0, 0.2);
}


/*Grey Panel*/

.grey-panel {
    text-align: center;
    background: #dfdfe1;
}

.grey-panel .grey-header {
    background: #ccd1d9;
    padding: 3px;
    margin-bottom: 15px;
}

.grey-panel h5 {
    font-weight: 200;
    margin-top: 10px;
}

.grey-panel p {
    margin-left: 5px;
}

/* Specific Conf for Donut Charts*/
.donut-chart p {
    margin-top: 20px;
    font-weight: 700;
    margin-left: 15px;
}

.donut-chart h2 {
    font-weight: 900;
    color: #FF6B6B;
    font-size: 38px;
}

/* Dark Blue*/

.darkblue-panel {
    text-align: center;
    background: #444c57;
}

.darkblue-panel .darkblue-header {
    background: transparent;
    padding: 3px;
    margin-bottom: 15px;
}

.darkblue-panel h1 {
    color: #f2f2f2;
}

.darkblue-panel h5 {
    font-weight: 200;
    margin-top: 10px;
    color: white;
}

.darkblue-panel footer {
    color: white;
}

.darkblue-panel footer h5 {
    margin-left: 10px;
    margin-right: 10px;
    font-weight: 700;
}

/*Green Panel*/
.green-panel {
    text-align: center;
    background: #4ECDC4;
}

.green-panel .green-header {
    background: #43b1a9;
    padding: 3px;
    margin-bottom: 15px;
}

.green-panel h5 {
    color: white;
    font-weight: 200;
    margin-top: 10px;
}

.green-panel h3 {
    color: white;
    font-weight: 100;
}

.green-panel p {
    color: white;
}

/*White Panel */
.white-panel {
    text-align: center;
    background: #ffffff;
}

.white-panel .white-header {
    background: #f3f3f3;
    padding: 3px;
    margin-bottom: 15px;
}

.white-panel .small {
    font-size: 10px;
    color: #ccd1d9;
}

/*STOCK CARD Panel*/
.card {
    background: white;
    box-shadow: 0 2px 1px rgba(0, 0, 0, 0.2);
    margin-bottom: 1em;
    height: 250px;
}

.stock .stock-chart {
    background: #00c5de;
}

.stock .stock-chart #chart {
    height: 10.7em;
    background: url(http://i.imgbox.com/abmuNQx2) center bottom no-repeat;
}

.stock .current-price {
    background: #1d2122;
    padding: 10px;
}

.stock .current-price .info abbr {
    display: block;
    color: #f8f8f8;
    font-size: 1.5em;
    font-weight: 600;
    margin-top: 0.18em;
}

.stock .current-price .changes {
    text-align: right;
}

.stock .changes .up {
    color: #4fd98b
}

.stock .current-price .changes .value {
    font-size: 1.8em;
    font-weight: 700;
}

.stock .summary {
    color: #2f2f2f;
    display: block;
    background: #f2f2f2;
    padding: 10px;
    text-align: center;
}

.stock .summary strong {
    font-weight: 900;
    font-size: 14px;
}

/*Content Panel*/
.content-panel {
    background: #ffffff;
    box-shadow: 0px 3px 2px #aab2bd;
    padding-bottom: 5px;
    clear: both;
}

.content-panel h4 {
    margin-left: 10px;
}

/*WEATHER PANELS*/

/* Weather 1 */
.weather {
    /* background: url(../img/weather.jpg) no-repeat center top; */
    text-align: center;
    background-position: center center;
}

.weather i {
    color: white;
    margin-top: 45px;
}

.weather h2 {
    color: white;
    font-weight: 900;
}

.weather h4 {
    color: white;
    font-weight: 900;
    letter-spacing: 1px;
}

/* Weather 2 */
.weather-2 {
    background: #e9f0f4;
}

.weather-2 .weather-2-header {
    background: #54bae6;
    padding-top: 5px;
    margin-bottom: 5px;
}

.weather-2 .weather-2-header p {
    color: white;
    margin-left: 5px;
    margin-right: 5px;
}

.weather-2 .weather-2-header p small {
    font-size: 10px;
}

.weather-2 .data {
    margin-right: 10px;
    margin-left: 10px;
    color: #272b34;
}

.weather-2 .data h5 {
    margin-top: 0px;
    font-weight: lighter;
}

.weather-2 .data h1 {
    margin-top: 2px;
}

/* Weather 3 */
.weather-3 {
    background: #ffcf00;
}

.weather-3 i {
    color: white;
    margin-top: 30px;
    font-size: 70px;
}

.weather-3 h1 {
    margin-top: 10px;
    color: white;
    font-weight: 900;
}

.weather-3 .info {
    background: #f2f2f2;
}

.weather-3 .info i {
    font-size: 15px;
    color: #c2c2c2;
    margin-bottom: 0px;
    margin-top: 10px;
}

.weather-3 .info h3 {
    font-weight: 900;
    margin-bottom: 0px;
}

.weather-3 .info p {
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 16px;
    color: #c2c2c2;
    font-size: 15px;
    font-weight: 700;
}


/* Message Panel */
.message-p {
    background: #f5f5f5;
}

.message-p .message-header {
    background: #dcdfe3;
    padding: 3px;
    margin-bottom: 15px;
    text-align: center;
}

.message-p h5 {
    font-weight: 200;
    margin-top: 10px;
}

.message-p p {
    margin-left: 10px;
}

.message-p .small {
    font-size: 11px;
    color: #aab2bd;
    margin-top: -10px;
}

.message-p name {
    color: #ed5565;
    font-weight: 700;
}

.message-p .message {
    font-size: 12px;
}

.message-p .form-inline {
    margin-left: 10px;
}

.message-p .form-inline .form-group {
    width: 80%;
}

.message-p .form-inline .form-control {
    width: 100%
}
















/* Font Awesome Showcase */
.fontawesome-list .fa-hover a {
    display: block;
    color: #222;
    line-height: 32px;
    height: 32px;
    padding-left: 10px;
    border-radius: 4px;
}

.fontawesome-list i {
    margin-right: 8px;
}








/* FORMS CONFIGURATION */
.form-panel {
    background: #ffffff;
    margin: 10px;
    padding: 10px;
    box-shadow: 0px 3px 2px #aab2bd;
    text-align: left;
}

label {
    font-weight: 400;
}

.form-horizontal.style-form .form-group {
    border-bottom: 1px solid #eff2f7;
    padding-bottom: 15px;
    margin-bottom: 15px;
}

.round-form {
    border-radius: 500px;
    -webkit-border-radius: 500px;
}

@media (min-width: 768px) {
    .form-horizontal .control-label {
        text-align: left;
    }
}

#focusedInput {
    border: 1px solid #ed5565;
    box-shadow: none;
}

.add-on {
    float: right;
    margin-top: -37px;
    padding: 3px;
    text-align: center;
}

.add-on .btn {
    height: 34px;
}


/*LOGIN CONFIGURATION PAGE*/
.form-login {
    max-width: 330px;
    margin: 100px auto 0;
    background: #fff;
    -webkit-border-radius: 5px;
    border-radius: 5px;
}

.form-login h2.form-login-heading {
    margin: 0;
    padding: 25px 20px;
    text-align: center;
    background: #77c6f5;
    -webkit-border-radius: 5px 5px 0 0;
    border-radius: 5px 5px 0 0;
    color: #fff;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 300;
}

.login-wrap {
    padding: 20px;
    -webkit-border-radius: 0 0 5px 5px;
    border-radius: 0 0 5px 5px;
}

.login-wrap .registration {
    text-align: center;
}

.login-social-link {
    display: block;
    margin-top: 20px;
    margin-bottom: 15px;
}




/* INVOICE CONF */
.well.green {
    background-color: #48cfad;
    color: #fff;
    border: none;
}

.well.well-small {
    padding: 13px;
    width: auto;
}

.invoice-body {
    padding: 30px;
}

.invoice-body h1 {
    font-weight: 900;
}

.invoice-body h4 {
    margin-left: 0px;
}

/* Map Wrap*/
#map {
    width: 100%;
    height: 450px;
}


/*PRICING TABLE*/
.custom-box {
    background: #FFFFFF;
    border: 1px solid #DCDBD7;
    margin: 30px 0;
    padding: 30px 20px 35px;
    text-align: center;
}

.servicetitle hr {
    border: 0 none;
    height: 3px;
    margin: 10px auto;
    width: 80px;
    background-color: #4ECDC4;
}

.icn-main-container {
    position: relative;
}

.icn-container {
    display: inline-block;
    width: 100px;
    height: 100px;
    border-radius: 500px;
    text-align: center;
    margin: 10px 0;
    font-weight: 600;
    font-size: 2.4em;
    line-height: 100px;
    color: #fff;
    background-color: #4ECDC4;
    -webkit-transition: all 0.6s ease-in-out;
    transition: all 0.6s ease-in-out;
}

.icn-container.active,
.icn-container:hover {
    background: #2B2E31 !important;
}

.pricing {
    list-style: none;
    padding: 10px 0 20px;
}

.pricing li {
    border-bottom: 1px solid #DCDBD7;
    font-size: 13px;
    font-weight: 100;
    line-height: 34px;
    margin: 0 auto;
    padding: 0;
    text-align: center;
    width: 70%;
    -webkit-transition: background-color .4s linear, color .4s linear;
    -moz-transition: background-color .4s linear, color .4s linear;
    -o-transition: background-color .4s linear, color .4s linear;
    -ms-transition: background-color .4s linear, color .4s linear;
    transition: background-color .4s linear, color .4s linear;
}

.pricing li:last-child {
    border-bottom: 0px solid #fff;
}


/* FAQ CONF */
.dmbox {
    margin: 0;
    border: 0;
    border: 1px solid #EDEDED;
    background-color: #fff;
    padding: 9px 18px;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    text-align: center;
    -webkit-transition: background 0.3s ease-out;
    transition: background 0.3s ease-out;
}

.dm-icon {
    display: inline-block;
    cursor: pointer;
    margin: 15px;
    width: 105px;
    height: 105px;
    border-radius: 50%;
    text-align: center;
    position: relative;
    z-index: 1;
    background: #2B2E31;
    color: #ffffff;
}

.dm-icon:after {
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    content: '';
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}

.dm-icon:before {
    /* speak: none; */
    line-height: 105px;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    display: block;
    -webkit-font-smoothing: antialiased;
}

.dm-icon:active,
.dm-icon:hover {
    background: #4ECDC4
}


/* 404 & 500 Error Pages Conf*/
.p404 img {
    margin-top: 120px;
}

.p404 h1 {
    font-weight: 900;
}


/* MAIL CONFIGURATION - INBOX, MAIL COMPOSE & MAIL VIEW  */
.mail-nav {
    margin: 15px -15px 0 -15px;
}

.mail-nav li a {
    border-radius: 0;
    -webkit-border-radius: 0;
    border-top: 1px solid #f2f3f6;
    padding: 15px;
    border-right: 3px solid rgba(0, 0, 0, 0);
    color: #a2a2a2;
}

.mail-nav>li>a:hover,
.mail-nav>li>a:focus {
    background: #fafafa;
    color: #4ECDC4;
    border-right: 3px solid #1fb5ad;
}

.mail-nav>li.active>a,
.mail-nav>li.active>a:hover,
.mail-nav>li.active>a:focus {
    background: #fafafa;
    color: #4ECDC4;
    border-right: 3px solid #4ECDC4;
}

.mail-nav>li+li {
    margin-left: 0;
    margin-top: 0px;
}

.mail-nav i {
    font-size: 16px;
    margin-right: 10px;
}

.inbox-notification {
    margin-top: -2px;
}

.user-head .inbox-avatar {
    width: 65px;
    float: left;
}

.user-head .inbox-avatar img {
    border-radius: 4px;
    -webkit-border-radius: 4px;
}

.user-head .user-name {
    display: inline-block;
    margin: 0 0 0 10px;
}

.user-head .user-name h5 {
    font-size: 14px;
    margin-top: 15px;
    margin-bottom: 0;
    font-weight: 300;
}

.user-head .user-name h5 a {
    color: #fff;
}

.user-head .user-name span a {
    font-size: 12px;
    color: #87e2e7;
}

a.mail-dropdown {
    background: #80d3d9;
    padding: 3px 5px;
    font-size: 10px;
    color: #01a7b3;
    border-radius: 2px;
    margin-top: 20px;
}

.inbox-body {
    padding: 20px;
}

.btn-compose {
    background: #4ECDC4;
    padding: 12px 0;
    text-align: center;
    width: 100%;
    color: #fff;
}

.btn-compose:hover,
.btn-compose:focus {
    background: #1ca59e;
    color: #fff;
}

.btn-compose i {
    padding: 5px;
}

ul.inbox-nav {
    display: inline-block;
    width: 100%;
    margin: 0;
    padding: 0;
}

.inbox-divider {
    border-bottom: 1px solid #d5d8df;
}

.inbox-action {
    margin-top: 50px;
}

.src-position {
    margin-top: -7px;
}

.mail-src-position {
    margin-top: -3px;
}

ul.labels-info {
    border-bottom: 1px solid #f1f2f7;
    margin-bottom: 10px;
}

ul.inbox-nav li {
    display: inline-block;
    line-height: 45px;
    width: 100%;
}

ul.inbox-nav li a {
    color: #6a6a6a;
    line-height: 45px;
    width: 100%;
    display: inline-block;
    padding: 0 20px;
}

ul.inbox-nav li a:hover,
ul.inbox-nav li.active a,
ul.inbox-nav li a:focus {
    color: #6a6a6a;
    background: #d5d7de;
}

ul.inbox-nav li a i {
    padding-right: 10px;
    font-size: 16px;
    color: #6a6a6a;
}

ul.inbox-nav li a span.label {
    margin-top: 13px;
}

ul.labels-info li h4 {
    padding-top: 5px;
    color: #5c5c5e;
    font-size: 15px;
    text-transform: uppercase;
}

ul.labels-info li {
    margin: 0;
}

ul.labels-info li a {
    color: #6a6a6a;
    border-radius: 0;
    padding-left: 0;
    padding-right: 0;
}

ul.labels-info li a:hover,
ul.labels-info li a:focus {
    color: #6a6a6a;
    background: none;
}

ul.labels-info li img {
    margin-right: 10px;
}

.nav.nav-pills.nav-stacked.labels-info p {
    margin-bottom: 0;
    padding: 0 22px;
    color: #9d9f9e;
    font-size: 11px;
}

.inbox-head {
    padding: 20px;
    background: #41cac0;
    color: #fff;
    border-radius: 0 4px 0 0;
    -webkit-border-radius: 0 4px 0 0;
    min-height: 80px;
}

.inbox-head h3 {
    margin: 0;
    display: inline-block;
    padding-top: 6px;
    font-weight: 300;
}

.inbox-head .sr-input {
    height: 40px;
    border: none;
    box-shadow: none;
    padding: 0 10px;
    float: left;
    border-radius: 4px 0 0 4px;
    color: #8a8a8a;
}

.inbox-head .sr-btn {
    height: 40px;
    border: none;
    background: #00a6b2;
    color: #fff;
    padding: 0 20px;
    border-radius: 0 4px 4px 0;
    -webkit-border-radius: 0 4px 4px 0;
}

.wht-bg {
    background: #fff;
}

.wht-bg h4 {
    font-size: 24px;
    color: #a2a2a2;
    font-weight: 300;
}

.table-inbox-wrap {
    margin: 0 -15px;
}

.mail-checkbox {
    margin-right: 10px;
}

.table-inbox {
    margin: 0;
}

.table-inbox tr td {
    padding: 12px !important;
}

.table-inbox tr td:first-child {
    width: 5%;
}

.table-inbox tr td:first-child .icheckbox_minimal {
    margin-left: 15px;
}

.table-inbox tr td:hover {
    cursor: pointer;
}

.table-inbox tr td .fa-star.inbox-started,
.table-inbox tr td .fa-star:hover {
    color: #f78a09;
}

.table-inbox tr td .fa-star {
    color: #d5d5d5;
}

.table-inbox tr.unread td {
    font-weight: 600;
    background: #f7f7f7;
}

.table-inbox tr.unread a {
    color: #32323a;
}

.table-inbox tr a {
    color: #767676;
}

ul.inbox-pagination {
    float: right;
}

ul.inbox-pagination li {
    float: left;
}

.pagination li a {
    color: #32323a;
}

.mail-option {
    display: inline-block;
    margin-bottom: 10px;
    width: 100%;
}

.mail-option .chk-all {
    float: left;
}

.mail-option .chk-all,
.mail-option .btn-group {
    margin-right: 5px;
}

.mail-option .chk-all,
.mail-option .btn-group a.btn {
    border: 1px solid #e7e7e7;
    padding: 5px 10px;
    display: inline-block;
    background: #fcfcfc;
    color: #afafaf;
    border-radius: 3px !important;
    -webkit-border-radius: 3px !important;
}

.inbox-pagination a.np-btn {
    border: 1px solid #e7e7e7;
    padding: 5px 15px;
    display: inline-block;
    background: #fcfcfc;
    color: #afafaf;
    border-radius: 3px !important;
    -webkit-border-radius: 3px !important;
}

.mail-option .chk-all input[type=checkbox] {
    margin-top: 0;
}

.mail-option .btn-group a.all {
    padding: 0;
    border: none;
}

.inbox-pagination a.np-btn {
    margin-left: 5px;
}

.inbox-pagination li span {
    display: inline-block;
    margin-top: 7px;
    margin-right: 5px;
}

.fileinput-button {
    border: 1px solid #e6e6e6;
    background: #eeeeee;
}

.inbox-body .modal .modal-body input,
.inbox-body .modal .modal-body textarea {
    border: 1px solid #e6e6e6;
    box-shadow: none;
}

.btn-send,
.btn-send:hover {
    background: #00A8B3;
    color: #fff;
}

.btn-send:hover {
    background: #009da7;
}

.modal-header h4.modal-title {
    font-weight: 300;
    font-family: 'Open Sans', sans-serif;
}

.modal-body label {
    font-weight: 400;
    font-family: 'Open Sans', sans-serif;
}



.compose-mail {
    width: 100%;
    display: inline-block;
    position: relative;
    margin-top: 20px;
}


.compose-mail .compose-options {
    color: #979797;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    position: absolute;
    right: 10px;
    top: 7px;
}

.compose-mail input,
.compose-mail input:focus {
    border: none;
    padding: 0;
    width: 80%;
    float: left;
}

.compose-mail .form-group {
    border: 1px solid #F2F3F6;
    display: inline-block;
    width: 100%;
    margin-bottom: 0;
}

.compose-mail .form-group label {
    line-height: 34px;
    width: 10%;
    float: left;
    padding-left: 5px;
    margin-bottom: 0;
}

.compose-editor input {
    margin-top: 15px;
}

.compose-editor {
    margin-bottom: 15px;
    display: inline-block;
    width: 100%;
}

.compose-btn {
    float: left;
    margin-top: 6px;
}

.mail-header h4 {
    font-weight: 300;
}

.mail-sender,
.attachment-mail {
    width: 100%;
    display: inline-block;
    margin: 20px 0;
    border-top: 1px solid #EFF2F7;
    border-bottom: 1px solid #EFF2F7;
    padding: 10px 0;

}

.mail-sender img {
    width: 30px;
    border-radius: 3px;
    -webkit-border-radius: 3px;
}

.mail-sender .date {
    line-height: 30px;
    margin-bottom: 0;
    text-align: right;
}

.view-mail a,
.attachment-mail a:hover {
    color: #4ECDC4;
}

.attachment-mail a {
    color: #4ECDC4;
}

.attachment-mail ul li {
    float: left;
    width: 200px;
    margin-right: 15px;
    margin-top: 15px;
    list-style: none;
}

.attachment-mail ul li a.atch-thumb img {
    width: 200px;
    height: 180px;
    margin-bottom: 10px;
}

.attachment-mail ul li a.name span {
    float: right;
    color: #767676;
}


/*CHAT ROOM & LOBBY CONFIGURATION*/

/*chat room*/
.chat-room {
    border-collapse: collapse;
    border-spacing: 0;
    display: table;
    table-layout: fixed;
    width: 100%;
    position: relative;
}

.chat-room aside {
    display: table-cell;
    float: none;
    height: 100%;
    padding: 0;
    vertical-align: top;
}

.chat-room .mid-side {
    width: 75%;
    background: #fff;
    border-right: 1px solid #e5e8ef;
}

.chat-room .right-side {
    width: 25%;
    background: #fff;
}

.chat-room .right-side .user-head {
    background: #3498db;
    color: #FFFFFF;
    min-height: 70px;
    padding: 10px;
    border-left: 1px solid #3498db;
    margin-left: -1px;
    position: relative;
}


.chat-room .user-head i {
    float: left;
    font-size: 40px;
    margin-right: 10px;
}

.chat-room .user-head h3 {
    margin: 6px 0 0 0;
    font-weight: 100;
    letter-spacing: 1px;
}

.chat-room-head {
    background: #4ECDC4;
    color: #FFFFFF;
    min-height: 70px;
    padding: 15px;
}

.chat-room-head h3 {
    margin: 5px 0 0;
    font-weight: 100;
    letter-spacing: 1px;
    display: inline-block;
}

.chat-room-head .search-btn {
    width: 20px;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
    box-shadow: none;
    background: #34495e;
    padding: 0 5px 0 35px;
    margin-top: 2px;
    border: none;
    color: #fff;
}

.chat-room-head .search-btn:focus {
    width: 180px;
    box-shadow: none;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
    /*background: #01a6b2;*/
    font-weight: 300;
    color: #fff;
}

.chat-room-head .search-btn:focus::-moz-placeholder {
    color: #fff;
}


ul.chat-list li a {
    color: #6a6a6a;
    display: block;
    padding: 15px;
    font-weight: 300;
}

ul.chat-list li a:hover,
ul.chat-list li.active a {
    color: #00a9b4;
    background: #f2f4f7;
}

ul.chat-list li h4 {
    padding: 17px 15px;
    margin: 0;
    font-size: 14px;
    font-weight: 600;
    border-bottom: 1px solid #D5D7DE;
}

ul.chat-list li h4 i {
    padding-right: 5px;
}

ul.chat-list li a span {
    padding-left: 10px;
}

ul.chat-list li a i.fa-times {
    color: #9fa3b0;
}

ul.chat-list li.active {
    color: #00a9b4;
    background: #f2f4f7;
}

ul.chat-list {
    border-bottom: 1px solid #d5d7de;
}

ul.chat-user {
    margin-bottom: 200px;
}

ul.chat-user li {
    border-bottom: none;
}

ul.chat-user li a:hover {
    background: none;
    color: #6a6a6a;
}

.chat-room .mid-side footer {
    background: #f6f6f6;
    padding: 15px;
    height: 70px;
    width: 75%;
    position: absolute;
    bottom: 0;
    border-right: 1px solid #E5E8EF;
}

.chat-room .right-side footer {
    background: #fff;
    padding: 15px;
    height: 70px;
    width: 25%;
    position: absolute;
    bottom: 0;
    border-top: 1px solid #E5E8EF;
    border-radius: 0 0 4px 0;
    -webkit-border-radius: 0 0 4px 0;
}

.room-desk {
    display: inline-block;
    margin-bottom: 30px;
    width: 100%;
    padding: 15px;
}

.room-desk h4 {
    text-transform: uppercase;
    font-weight: 300;
    font-size: 16px;
    margin: 5px 0 0 0;
}

.room-box {
    border: 1px solid #e7e7e7;
    background: #f7f8fa;
    padding: 10px;
    display: inline-block;
    width: 100%;
    margin-top: 10px;
    border-radius: 2px;
    -webkit-border-radius: 2px;
}

.room-box:hover {
    background: #f0f0f0
}

.room-box h5 {
    margin: 0 0 5px 0;
    font-weight: 300;
    font-size: 16px;
}

.room-box h5 a {
    color: #4ECDC4;
}

.chat-tools {
    float: right;
    padding: 3px;
    width: 40px;
    height: 35px;
    line-height: 30px;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    text-align: center;
    margin-top: 6px;
    margin-left: 10px;
}

.chat-tools i {
    font-size: 16px !important;
    float: none !important;
    margin-right: 0 !important;
    color: #fff;
}

.invite-row {
    background: #ecf0f1;
    padding: 6px 10px;
    display: inline-block;
    width: 100%;
}

.invite-row h4 {
    font-size: 16px;
    font-weight: 300;
}

ul.chat-available-user {
    padding: 10px;
}

ul.chat-available-user li {
    margin-bottom: 15px;
}

ul.chat-available-user li a {
    color: #6a6a6a;
}

ul.chat-available-user li a:hover {
    color: #4ECDC4;
}


.group-rom {
    width: 100%;
    float: left;
    border-bottom: 1px solid #eaebee;
}

.group-rom .first-part,
.group-rom .second-part,
.group-rom .third-part {
    float: left;
    padding: 15px;
}

.group-rom .first-part {
    width: 25%;
}

.group-rom .first-part.odd {
    background: #f7f8fa;
    color: #6a6a6a;
    font-weight: 600;
}

.group-rom .second-part {
    width: 60%;
}

.group-rom .third-part {
    width: 15%;
    color: #d4d3d3;
}

.last-group {
    margin-bottom: 70px;
}

.chat-txt {
    float: left;
    width: 75%;
    margin-right: 5px;
}

.lobby {
    padding: 0 !important;
}


/* GOOGLE MAPS - SCRIPT HELPER*/
#gmap-list,
#gmap-tabs {
    height: 450px;
}

.gmaps {
    height: 300px;
    width: 100%;
}

.ullist {
    border-bottom: #ccc 1px solid;
    margin-left: 20px;
}

.ullist li {
    display: inline-block;
    margin-left: 5px;
}

.ullist li a {
    display: inline-block;
    padding: 10px 20px !important;
    border-top: #ccc 1px solid;
    border-left: #ccc 1px solid;
    border-right: #ccc 1px solid;
    -moz-border-radius: 3px 3px 0 0;
    -webkit-border-radius: 3px 3px 0 0;
    border-radius: 3px 3px 0 0;
    background: #f1f1f1;
}

.ullist li.active a {
    background: #fff;
    border-bottom: #fff 1px solid;
    position: relative;
    top: 1px;
    margin-bottom: -1px;
}


.btn-facebook {
    color: #fff;
    background-color: #5193ea;
    border-color: #2775e2;
}

.btn-facebook:hover,
.btn-facebook:focus,
.btn-facebook:active,
.btn-facebook.active,
.open .dropdown-toggle.btn-facebook {
    color: #fff;
    background-color: #2775e2;
    border-color: #2775e2;
}

.btn-twitter {
    color: #fff;
    background-color: #44ccfe;
    border-color: #2bb4e8;
}

.btn-twitter:hover,
.btn-twitter:focus,
.btn-twitter:active,
.btn-twitter.active,
.open .dropdown-toggle.btn-twitter {
    color: #fff;
    background-color: #2bb4e8;
    border-color: #2bb4e8;
}




/*fontawesome*/
.fontawesome-icon-list h2 {
    margin-top: 0;
    font-size: 20px;
    font-weight: 300;
}

.fontawesome-icon-list .col-sm-4,
.fontawesome-icon-list .col-md-3,
.fontawesome-icon-list .col-xs-6 {
    margin-bottom: 10px;
}

.fontawesome-icon-list .page-header {
    border-bottom: 1px solid #C9CDD7;
}

.fontawesome-icon-list a {
    color: #797979;
}

.fontawesome-icon-list a:hover {
    color: #35404D;
}

.fontawesome-icon-list i {
    font-size: 16px;
    padding-right: 10px;
}

.user-img {
    width: 40px;
    height: 40px;
}

.user-dd {
    left: auto;
    right: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: #3e4095;
}

.table thead tr {
    background-color: #3da0de;
    color: #ffffff;
}

.clearfix::after {
    content: "";
    clear: both;
    display: table;
}

.mt-17 {
    margin-top: 17px;
}

.tbl-task {
    margin-bottom: 0 !important;
}

.tbl-task thead tr th:nth-child(1),
.tbl-task tbody tr td:nth-child(1) {
    text-align: center;
    width: 8%;
    word-break: break-all;
}

.tbl-task thead tr th:nth-child(2),
.tbl-task tbody tr td:nth-child(2) {
    text-align: center;
    width: 20%;
    word-break: break-all;
}

.tbl-task thead tr th:nth-child(3),
.tbl-task tbody tr td:nth-child(3) {
    text-align: center;
    width: 8%;
    word-break: break-all;
}

.tbl-task thead tr th:nth-child(4),
.tbl-task tbody tr td:nth-child(4) {
    text-align: center;
    width: 14%;
    word-break: break-all;
}

.tbl-task thead tr th:nth-child(5),
.tbl-task tbody tr td:nth-child(5) {
    text-align: center;
    width: 14%;
    word-break: break-all;
}

.tbl-task thead tr th:nth-child(6),
.tbl-task tbody tr td:nth-child(6) {
    text-align: center;
    width: 10%;
    word-break: break-all;
}

.tbl-task thead tr th:nth-child(7),
.tbl-task tbody tr td:nth-child(7) {
    text-align: center;
    width: 7%;
    word-break: break-all;
}

.tbl-task thead tr th:nth-child(8),
.tbl-task tbody tr td:nth-child(8) {
    text-align: center;
    width: 9%;
    word-break: break-all;
}

.tbl-task thead tr th:nth-child(9),
.tbl-task tbody tr td:nth-child(9) {
    text-align: center;
    width: 9%;
    word-break: break-all;
}

.tbl-task tbody tr td.gnt-report,
.tbl-task thead tr th.gnt-report {
    text-align: center;
    width: 20%;
    word-break: break-all;
}

.tbl-question thead tr th:nth-child(1) {
    width: 70%;
}

.tbl-question thead tr th:nth-child(3),
.tbl-question tbody tr td:nth-child(3) {
    text-align: center;
}

.table-wrap {
    display: block;
    max-height: 250px;
    overflow-y: scroll;
}

.form-control {
    color: #000000;
    border-radius: 17px;
    border: 1px solid #9e9fa0;
}

.upload-holder {
    position: relative;
    display: block;
    width: 100%;
}

.input-upload {
    padding: 6px 50px 6px 12px !important;
    width: 90% !important;
}

.btn-upload {
    background-color: #337ab7;
    color: #ffffff;
    padding: 7px 15px;
    border-radius: 0 17px 17px 0;
    position: absolute;
    right: 0;
    top: 0;
}

.input-upload[readonly] {
    background-color: #ffffff;
}

.input-hidden {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    filter: alpha(opacity=0);
}

.mr-10 {
    margin-right: 10px;
}

.btn-prim {
    background-color: transparent;
    border: 1px solid #204d74;
    color: #204d74;
    min-width: 90px;
}

.btn-prim:hover,
.btn-prim:focus {
    background-color: #204d74;
    border: 1px solid #204d74;
    color: #ffffff;
}

.min-wid-90 {
    min-width: 90px;
}

.edit-icon {
    margin-right: 10px;
    color: #000000;
    cursor: pointer;
}

.delete-icon {
    margin-left: 10px;
    margin-right: 20px;
    color: #ff0000;
    cursor: pointer;
}

.remove-icon {
    color: red;
    float: right;

    width: 6%;

}

.black-text {
    color: #000000;
}

.ques-box {
    margin: 10px 0;
    display: block;
    width: 100%;
    height: auto;
    overflow: hidden;
    background-color: #efefef;
    padding: 10px;
}

.ques {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.ques li {
    margin-bottom: 1rem;
    list-style-type: decimal;
    margin-left: 30px;
}

/*Custom Control*/
.custom-selection {
    position: relative;
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5rem;
}

.custom-selection span {
    min-width: 80px;
    display: inline-block;
    padding: 0 10px;
}

.custom-selection span label {
    margin-right: 5px;
}

.custom-selection span input[type="checkbox"],
.custom-selection span input[type="radio"] {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.custom-selection span input[type="checkbox"]:checked~label::before,
.custom-selection span input[type="radio"]:checked~label::before {
    color: #fff;
    border-color: #4ab6ec;
    background-color: #4ab6ec;
}

.custom-selection span input[type="checkbox"]:focus~label::before,
.custom-selection span input[type="radio"]:focus~label::before {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-selection span input[type="checkbox"]:focus:not(:checked)~label::before,
.custom-selection span input[type="radio"]:focus:not(:checked)~label::before {
    border-color: #80bdff;
}

.custom-selection span input[type="checkbox"]:not(:disabled):active~label::before,
.custom-selection span input[type="radio"]:not(:disabled):active~label::before {
    color: #fff;
    background-color: #b3d7ff;
    border-color: #b3d7ff;
}

.custom-selection span input[type="checkbox"]:disabled~label,
.custom-selection span input[type="radio"]:disabled~label {
    color: #6c757d;
}

.custom-selection span input[type="checkbox"]:disabled~label::before,
.custom-selection span input[type="radio"]:disabled~label::before {
    background-color: #e9ecef;
}

.custom-selection span label {
    position: relative;
    margin-bottom: 0;
    vertical-align: top;
}

.custom-selection span label::before {
    position: absolute;
    top: 0.15rem;
    left: -1.9rem;
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px;
}

.custom-selection span label::after {
    position: absolute;
    top: 0.15rem;
    left: -1.9rem;
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    content: "";
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
}

.custom-selection span label::before {
    border-radius: 0;
}

.custom-selection span input[type="checkbox"]:checked~label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}

.custom-selection span input[type="checkbox"]:indeterminate~label::before {
    border-color: #a4a8ad;
    background-color: #007bff;
}

.custom-selection span input[type="checkbox"]:indeterminate~label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}

.custom-selection span input[type="checkbox"]:disabled:checked~label::before {
    background-color: #c5c5c5;
    border: 1px solid #bbbbbb;
}

.custom-selection span input[type="checkbox"]:disabled:indeterminate~label::before {
    background-color: rgba(0, 123, 255, 0.5);
}

.custom-selection span input[type="radio"]~label::before {
    border-radius: 50%;
}

.custom-selection span input[type="radio"]:checked~label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.custom-selection span input[type="radio"]:checked~label::before {
    background-color: rgba(0, 123, 255, 0.5);
}

.custom-selection span input[type="radio"]:disabled:checked~label::before {
    background-color: #c5c5c5;
    border: 1px solid #bbbbbb;
}

/*Custom Control End*/
.back-image {
    background: url(../img/login-back.jpg) scroll no-repeat top left #ffffff;
    background-size: cover;
    height: 100%;
}

html,
body {
    height: 100%;
}

.h-100 {
    height: 100%;
}

.tbl-height {
    height: 250px;
    overflow-y: scroll;
}

.opt-ans {
    padding: 0;
    margin: 0;
}

.opt-ans li {
    list-style-type: circle;
    text-align: left;
    margin-left: 30px;
}

.btn .caret {
    float: right;
    margin-top: 8px;
}

.multiselect-native-select .btn-group {
    width: 100%;
    text-align: left;
}

.multiselect-native-select .btn-group .multiselect {
    text-align: left;
    width: 100%;
    border-radius: 17px;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
    width: 100%;
}

.tbl-holder {
    border: 1px solid #ddd;
    border-radius: 25px;
    overflow: hidden;
    position: relative;
}

.headercontainer {
    position: relative;
    border: 1px solid #222;
    padding-top: 37px;
    background: #000;
}

.dropdown-menuuser-dd {
    position: absolute;
    float: left;
    top: 100%;
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    font-size: 14px;
    text-align: center;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: 4px;
    z-index: 1000;
    box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    left: auto;
    right: 10px;
}

.dropdown-menuuser-dd li.divider {
    height: 1px;
    background-color: #e5e5e5;
    margin: 9px 0;
    overflow: hidden;

}

.mt-20 {
    margin-top: 20px;
}

.mt-25 {
    margin-top: 25px;
}

.pl-0 {
    padding-left: 0 !important;
}

.pl-10 {
    padding-left: 12;
}

.ml-900 {
    margin-left: 900 !important;
}

.ml-0 {
    margin-left: 0;
}

.d-none {
    display: none;
}

.ml-10 {
    margin-left: 10px;
}

.ml-20 {
    margin-left: 20px;
}

.mh-100 {
    min-height: 100px;
}

.grab {
    cursor: -webkit-grabbing;
    cursor: grabbing;
}

a {
    cursor: pointer;
}

.form-horizontal .control-label {
    text-align: left !important;
}

.d-flex {
    display: -ms-flexbox !important;
    display: flex !important;
}

.flex-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
}

.flex-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
}

.justify-content-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
}

.justify-content-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
}

.fbasis-94 {
    flex-basis: 94%;
}

.mb-10 {
    margin-bottom: 10px;
}

.cloader {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -60px;
    margin-top: -60px;
    z-index: 9999;
}

.animate {
    -webkit-animation: animatezoom 0.6s;
    animation: animatezoom 0.6s
}

@-webkit-keyframes animatezoom {
    from {
        -webkit-transform: scale(0)
    }

    to {
        -webkit-transform: scale(1)
    }
}

@keyframes animatezoom {
    from {
        transform: scale(0)
    }

    to {
        transform: scale(1)
    }
}

.spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    size: 80;
    /* spinnerColor:grey;  */
    color: '#fff';
    /* spinnerWidth:2; */
    display: table;
    margin: 0 auto;
    z-index: 9999;
}

.d-none {
    display: none;
}

.mlr-10 {
    margin: 0 10px;
}

.nameloc {
    min-width: 170px !important;
    max-width: 170px;
    margin: 0 5px;
}

.hintred {
    color: #ff0000;
    font-size: 10px;
}

.mt-5 {
    margin-top: 5px;
}

.viewlogo {
    height: 25px;
    width: 25px;
}

.m-5 {
    margin: 5px;
}

.close {
    opacity: 1 !important;
}

.react-confirm-alert-overlay {
    z-index: 1060;
}

.tbl-ticker {
    margin-bottom: 0 !important;
}

.tbl-ticker thead tr th:nth-child(1),
.tbl-ticker tbody tr td:nth-child(1) {
    width: 55%;
    word-break: break-all;
}

.tbl-ticker thead tr th:nth-child(2),
.tbl-ticker tbody tr td:nth-child(2),
.tbl-ticker thead tr th:nth-child(3),
.tbl-ticker tbody tr td:nth-child(3),
.tbl-ticker thead tr th:nth-child(4),
.tbl-ticker tbody tr td:nth-child(4) {
    width: 15%;
    word-break: break-all;
}

.box-scroll {
    overflow-y: scroll;
    height: 450px;
}

.samp-mg {
    margin: 5px 0 20px 0;
}

.mob-show {
    display: none;
}

/* .name-show{display:block;} */
p.marquee {
    animation-name: textslide;
    animation-timing-function: linear;
    animation-duration: 50s;
    animation-iteration-count: infinite;
    margin: 0;
    padding: 0;
    overflow: hidden;
    display: block;
    white-space: nowrap;
    width: 100%;
}

p.marquee:hover {
    -webkit-animation-play-state: paused;
    -moz-animation-play-state: paused;
    -o-animation-play-state: paused;
    animation-play-state: paused;
}

@keyframes textslide {
    0% {
        text-indent: 100%
    }

    100% {
        text-indent: -100%
    }
}

.tbwdth {
    width: 100% !important
}

.piesummary {
    width: 15px;
    height: 15px;
    display: inline-block;
    margin-right: 5px
}

.dahboard {
    width: 240px;
    padding: 20px;
    border: 0px solid white;
    margin: 0;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
    background-color: rgba(255, 255, 255, 0.9);
    background: #f4f5f4;
    display: inline-block;
    margin-right: 20px;
    text-align: center
}

.markcenter {
    margin-left: 140px;
    position: absolute;
    float: left;
    width: 70%;
    padding: 15px 0 5px;
    color: #ff0000;
    font-weight: bold
}




.imgalign {
    text-align: right;
}

.pie-holder {
    display: flex;
    justify-content: space-between;
}

.imgholder {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.pagein {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
}

.pagein li {
    padding: 5px;
}

.ig-modal {
    width: 530px;
}

@media screen and (max-width: 768px) {

    .tbl-task,
    .tbl-task thead,
    .tbl-task tbody,
    .tbl-task th,
    .tbl-task td,
    .tbl-task tr,
    .tbl-genDash,
    .tbl-genDash thead,
    .tbl-genDash tbody,
    .tbl-genDash th,
    .tbl-genDash td,
    .tbl-genDash tr,
    .tbl-ticker,
    .tbl-ticker thead,
    .tbl-ticker tbody,
    .tbl-ticker th,
    .tbl-ticker td,
    .tbl-ticker tr {
        display: block !important;
    }

    .tbl-task tbody tr,
    .tbl-genDash tbody tr,
    .tbl-ticker tbody tr {
        margin-bottom: 1em !important;
        display: block !important;
        border: 1px solid #e3e6ec !important;
    }

    .tbl-task tbody tr td,
    .tbl-genDash tbody tr td,
    .tbl-ticker tbody tr td {
        border-top: none !important;
        border-bottom: 1px solid #e3e6ec !important;
        position: relative !important;
        width: 100% !important;
        text-align: left !important;
        padding: 6px 6px 6px 32% !important;
        min-height: 40px !important;
        border-right: none !important;
    }

    .tbl-genDash tbody tr td {
        border-top: none !important;
        border-bottom: 1px solid #e3e6ec !important;
        position: relative !important;
        width: 100% !important;
        text-align: left !important;
        padding: 6px 6px 6px 32% !important;
        min-height: 40px !important;
        border-right: none !important;
    }

    .tbl-task tbody td:before,
    .tbl-genDash tbody td:before,
    .tbl-ticker tbody td:before {
        content: attr(data-th);
        font-weight: bold;
        display: inline-block;
        width: 30%;
        background: none repeat scroll 0% 0% #3da0de;
        padding: 0.6rem;
        left: 0px;
        position: absolute;
        margin-right: 10px;
        color: #ffffff;
        top: 0;
        bottom: 0;
        white-space: nowrap;
    }

    .tbl-task tbody td.gnt-report:before {
        content: attr(data-th);
        font-weight: bold;
        display: inline-block;
        width: 30% !important;
        background: none repeat scroll 0% 0% #3da0de;
        padding: 0.6rem;
        left: 0px;
        position: absolute;
        margin-right: 10px;
        color: #ffffff;
        top: 0;
        bottom: 0;
        white-space: nowrap;
    }

    .tbl-hhide {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }

    .yscroll {
        overflow-y: scroll
    }

    .mob-tbl {
        border: none !important;
        border-top-left-radius: 27px;
        overflow: hidden;
    }

    .mob-show {
        display: block;
    }

    .name-show {
        display: none;
    }

    .tbwdth {
        width: 100% !important
    }

    .dahboard {
        width: 145px
    }

    .piesummary {
        margin-right: 15px
    }

    .ig-modal {
        width: 300px;
    }

    .markcenter {
        margin-left: 0px;
        margin-top: 50px;
        width: 100%
    }

    /*td:nth-of-type(1):before { content: "First Name"; }*/
}

@media screen and (min-width: 768px) and (max-width:992px) {
    .w-sm-110 {
        width: 110px !important;
    }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.css-14y8zgr-control {
    border: 1px solid #000000 !important;
}

#chart {
    height: 440px;
}

.label-radio {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: 700;
    padding-left: 15px;
}

.row-rad {
    margin-right: 15px !important;
    margin-left: 15px !important;
}

.tbl-genDash {
    margin-bottom: 0 !important;
}

.tbl-genDash thead tr th:nth-child(1),
.tbl-genDash tbody tr td:nth-child(1) {
    width: 15%;
    word-break: break-all;
}

.tbl-genDash thead tr th:nth-child(2),
.tbl-genDash tbody tr td:nth-child(2),
/* {width:25%;word-break: break-all;} */
.tbl-genDash thead tr th:nth-child(3),
.tbl-genDash tbody tr td:nth-child(3),
.tbl-genDash thead tr th:nth-child(4),
.tbl-genDash tbody tr td:nth-child(4),
.tbl-genDash thead tr th:nth-child(5),
.tbl-genDash tbody tr td:nth-child(5),
.tbl-genDash thead tr th:nth-child(6),
.tbl-genDash tbody tr td:nth-child(6),
.tbl-genDash thead tr th:nth-child(7),
.tbl-genDash tbody tr td:nth-child(7) {
    text-align: center;
    width: 5%;
    word-break: break-all;
}

.tbl-genDash thead tr th:nth-child(8),
.tbl-genDash tbody tr td:nth-child(8) {
    text-align: center;
    width: 6%;
    word-break: break-all;
}

.tbl-genDash thead tr th:nth-child(9),
.tbl-genDash tbody tr td:nth-child(9),
.tbl-genDash thead tr th:nth-child(10),
.tbl-genDash tbody tr td:nth-child(10) {
    text-align: center;
    width: 5%;
    word-break: break-all;
}

.tbl-DashTitle {
    margin-bottom: 0 !important;
}

.tbl-DashTitle thead tr th:nth-child(1),
.tbl-DashTitle tbody tr td:nth-child(1) {
    text-align: center;
    width: 15%;
    word-break: break-all;
}

.tbl-DashTitle thead tr th:nth-child(2),
.tbl-DashTitle tbody tr td:nth-child(2) {
    text-align: center;
    width: 25%;
    word-break: break-all;
}

.tbl-DashTitle thead tr th:nth-child(3),
.tbl-DashTitle tbody tr td:nth-child(3) {
    text-align: center;
    width: 16%;
    word-break: break-all;
}

.mt-20 {
    margin-top: 20px
}

.overlay-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    background: #666666;
    opacity: .8;
    z-index: 1000;
    pointer-events: none;
}

.parentDisable {
    position: fixed;
    top: 0;
    left: 0;
    background: rgb(248 248 248);
    opacity: 0.8;
    z-index: 998;
    height: 100%;
    width: 100%;
}

.loader {
    border: 4px solid #f3f3f3;
    border-radius: 50%;
    border-top: 4px solid #3498db;
    width: 35px;
    height: 35px;
    animation: spin 2s linear infinite;
}


/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* CSS for the dropdown button */
.dropdown {
    position: relative;
    display: inline-block;
}

.dropbtn {
    background-color: #3498db;
    color: white;
    padding: 10px 15px;
    border: none;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.tbl-task .store-length {
    width: 8% !important;
}

.tbl-task .date-length {
    width: 12% !important
}

.tbl-task .task-name-length {
    width: 20% !important
}

.store-total {
    color: #3da0de;
    font-weight: bold
}

.btn-prev-report {
    margin-left: 15px;
    margin-right: 60px;
}